<template>
  <CLink
    :to="{name: 'contact_message'}"
    translated
    :label="$t('views.action.new')"
    icon="email-plus-outline"
    class="btn-a-outline"
  />
  <CMessage :messages="message.base" />
  <router-link
    v-for="(item, index) in contact_messages"
    :key="index"
    :to="{ name: 'contact_message_thread', params: {id: item.contact_message_id}}"
    class="card-a hover:bg-primary-hover"
  >
    <div>
      <mdicon :name="item.admin_id ? 'face-agent' : 'account'" />
      <label class="label-a text-secondary">{{ (new Date(item.created_at)).toLocaleString($i18n.locale) }}</label>
      <CBadge :count="unread_message_counts[item.id]" />
    </div>
    <h3 class="font-bold">
      {{ item.title }}
    </h3>
    <p
      v-if="item.body"
      v-html="item.body.substring(0, 50) + '...'"
    />
  </router-link>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: 'ContactMessages',
  data() {
    return {
      message: {},
      unread_message_counts: [],
      contact_messages: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('current_user/contact_messages').then(
        response => {
          this.contact_messages = response.data.contact_messages
          this.unread_message_counts = response.data.unread_message_counts
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    }
  }
}
</script>