<template>
  <CLanguage />
  <div class="grid grid-cols-2">
    <router-link
      v-for="(item, index) in items"
      :key="index"
      :to="{name: item.name}"
      class="block h-20 text-center border border-primary mx-2 my-2 px-2 py-2 hover:bg-primary-hover"
    >
      <mdicon
        :name="item.icon"
        class="block-icon"
      /> 
      {{ $t('views.route.' + item.name) }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Menu",
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    items(){
      let links = [];
      //links.push({ name: 'anti_social', icon: 'shield-sword' })
      links.push({ name: 'home', icon: 'home' });
      links.push({ name: 'exchange_rate', icon: 'currency-jpy' });
      links.push({ name: 'posts', icon: 'posts' });
      //links.push(this.isLoggedIn ? { name: 'contact_messages', icon: 'email-edit' } : { name: 'contact_message', icon: 'email-edit' })
      if (this.isLoggedIn) {
          links.push({ name: 'profile', icon: 'account' })
          links.push({ name: 'license', icon: 'license', icon_fixed: true })
          links.push({ name: 'change_password', icon: 'lock' })
          links.push({ name: 'sessions', icon: 'shield-account' })
          //{ name: 'recipients', icon: 'account-arrow-left-outline' },
          /*{ name: 'deposit_accounts', icon: 'bank-transfer-in' },*/
          //{ name: 'contact_messages', icon: 'email-edit-outline' },
          links.push({ name: 'logout', icon: 'logout' })
      } else {
        links.push({ name: 'register', icon: 'account-plus' });
        links.push({ name: 'contact_message', icon: 'email-edit' });
      }
      links.push({ name: 'terms', icon: 'shield-check-outline' })
      links.push({ name: 'privacies', icon: 'shield-lock-outline' })
      if(this.$i18n.locale == 'ja') {
        links.push({ name: 'laws', icon: 'note-outline' })
      }
      return links
    }
  }
}
</script>