import {createRouter,createWebHashHistory} from 'vue-router';
import About from "../pages/About";
//import Home from "../pages/Home";
import Guides from "../pages/Guides";
import Terms from "../pages/Terms";
import Helps from "../pages/Helps";
import Help from "../pages/Help";
import Privacies from "../pages/Privacies";
import Laws from "../pages/Laws";
import AntiSocial from "../pages/AntiSocial";
import Register from "../pages/Register";
import ResetPassword from "../pages/ResetPassword";
import Login from "../pages/Login";
import LoginWithGoogle from "../pages/LoginWithGoogle";
import Logout from "../pages/Logout";
import Profile from "../pages/Profile";
import Recipient from "../pages/Recipient";
//import Recipients from "../pages/Recipients";
import ChangePassword from "../pages/ChangePassword";
//import Orders from "../pages/Orders";
//import OrderForm from "../pages/OrderForm";
import Menu from "../pages/Menu"
//import CDeposit from "../components/CDeposit"
import Sessions from "../pages/Sessions"
import ContactMessage from "../pages/ContactMessage"
import ContactMessages from "../pages/ContactMessages"
import ContactMessageThread from "../pages/ContactMessageThread"
import ExchangeRate from "../pages/ExchangeRate"
import BudgetSheetPlan from "../pages/budget_sheets/BudgetSheetPlan"
import BudgetSheetDo from "../pages/budget_sheets/BudgetSheetDo"
import BudgetSheetCheck from "../pages/budget_sheets/BudgetSheetCheck"
import BudgetSheetAction from "../pages/budget_sheets/BudgetSheetAction"
import Plans from "../pages/plans/Plans"
import PlanRegister from "../pages/plans/PlanRegister"
import License from "../pages/License"
import Posts from "../pages/Posts"
import Post from "../pages/Post"
import Scoring from "../pages/Scoring"

const routes = [
  { name: 'home', path: '/', component: About },
  { name: 'terms', path: '/terms', component: Terms },
  { name: 'privacies', path: '/policies', component: Privacies },
  { name: 'laws', path: '/laws', component: Laws },
  { name: 'anti_social', path: '/anti-social', component: AntiSocial },
  { name: 'guides', path: '/guides', component: Guides },
  { name: 'register', path: '/register', component: Register },
  { name: 'reset_password', path: '/reset-password', component: ResetPassword },
  { name: 'login', path: '/login', component: Login },
  { name: 'login_with_google', path: '/login-with-google/:tk', component: LoginWithGoogle },
  { name: 'logout', path: '/logout', component: Logout },
  { name: 'profile', path: '/profile', component: Profile },
  //{ name: 'recipients', path: '/recipients', component: Recipients },
  { name: 'recipient', path: '/recipient', component: Recipient },
  { name: 'change_password', path: '/change-password', component: ChangePassword },
  //{ name: 'orders', path: '/orders', component: Orders },
  //{ name: 'order_form', path: '/order-form', component: OrderForm },
  //{ name: 'deposit_accounts', path: '/deposit-accounts', component: CDeposit },
  { name: 'sessions', path: '/sessions', component: Sessions },
  { name: 'contact_message', path: '/contact-message', component: ContactMessage },
  { name: 'contact_messages', path: '/contact-messages', component: ContactMessages },
  { name: 'contact_message_thread', path: '/contact-messages/:id', component: ContactMessageThread },
  { name: 'menu', path: '/menu', component: Menu },
  { name: 'help_list', path: '/help', component: Helps },
  { name: 'help', path: '/help/:id', component: Help },
  { name: 'exchange_rate', path: '/exchange-rate', component: ExchangeRate },
  { name: 'budget_sheet_plan', path: '/budget-sheet/plan', component: BudgetSheetPlan },
  { name: 'budget_sheet_do', path: '/budget-sheet/do', component: BudgetSheetDo },
  { name: 'budget_sheet_check', path: '/budget-sheet/check', component: BudgetSheetCheck },
  { name: 'budget_sheet_action', path: '/budget-sheet/action', component: BudgetSheetAction },
  { name: 'plans', path: '/plans', component: Plans },
  { name: 'plan_register', path: '/plan-prices/:id/register', component: PlanRegister },
  { name: 'license', path: '/license', component: License },
  { name: 'scoring', path: '/scoring', component: Scoring },
  { name: 'posts', path: '/posts', component: Posts },
  { name: 'post', path: '/post/:id', component: Post },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
 
export default router;