import { createI18n } from 'vue-i18n'
import vi from './tVi.json';
import ja from './tJa.json';
const i18n = createI18n({
  locale: 'vi',
  fallbackLocale: 'ja',
  messages: {
    vi,
    ja
  },
})

export default i18n;