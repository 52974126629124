<template>
  <div class="app">
    <div class="header">
      <a href="/"><img src='/logo.svg' alt="logo" /></a>
    </div>
    <div class="main">
      <div class="sidebar sm-except">
        <CLanguage />
        <div class="menu mt-1">
          <!---
          <router-link :to="{name: 'order_form'}" class="btn-a">
            <mdicon class="text-center" :name="'rocket-launch' + ($route.name=='order-form' ? '' : '-outline')" />
            {{ $t('views.action.remit') }}
          </router-link>
          -->
          <router-link v-for="(item, index) in accessibleLinks" :key="item.name" :class="is_active(item) ? 'menu-item-active' : 'menu-item'" :to="{ name: item.name }">
            <mdicon class="text-center" :name="item_icon(item)" />
            <CBadge v-if="item.name=='contact_messages'" :count="contact_messages_unread_count" />
            {{ $t('views.route.' + item.name) }}
          </router-link>
          <router-link v-if="isLoggedIn" class="menu-item" :to="{ name: 'logout' }">
            <mdicon class="text-center" name="logout" />
            {{ $t('views.route.logout') }}
          </router-link>
        </div>
      </div>
      <div class="content">
        <div>
          <h3 class="header-a">{{ $t('views.route.' + $route.name) }}</h3>
        </div>
        <router-view />
      </div>
    </div>
    <div class="sm-only">
      <div class="h-14">
      </div>
      <div class="sm-footer">
        <router-link
          v-for="(item, index) in footer_menus"
          :to="{name: item.name}"
          :class="is_active(item) ? 'sm-menu-item-active' : 'sm-menu-item'"
        >
          <mdicon class="block-icon" :name="item_icon(item)" />
          <CBadge v-if="item.name=='contact_messages'" :count="contact_messages_unread_count" />
          {{ $t('views.route.' + item.name) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequestor from './src/common/ApiRequestor'
export default {
  name: "App",
  data() {
    return {
      processing: false,
      contact_messages_unread_count: 0
    }
  },
  computed: {
    languages(){
      return [
        { value: 'vi', label: this.$t('views.language.vi'), icon: 'fi fi-vn' },
        { value: 'ja', label: this.$t('views.language.ja'), icon: 'fi fi-jp' }
      ]
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    accessibleLinks() {
      let links = [];
      links.push({ name: 'home', icon: 'home' });
      if (this.isLoggedIn) {
        //links.push({ name: 'recipients', icon: 'account-arrow-left' });
        /*links.push({ name: 'deposit_accounts', icon: 'bank' });*/
        //links.push({ name: 'orders', icon: 'file-clock' });
        links.push({ name: 'scoring', icon: 'counter', icon_fixed: true });
        links.push({ name: 'contact_messages', icon: 'email-edit' });
        links.push({ name: 'budget_sheet_plan', icon: 'piggy-bank', alt_names: ['budget_sheet_do','budget_sheet_check','budget_sheet_action']});
        links.push({ name: 'exchange_rate', icon: 'currency-jpy', icon_fixed: true });
        links.push({ name: 'posts', icon: 'post'})
        links.push({ name: 'profile', icon: 'account' });
        links.push({ name: 'license', icon: 'license', icon_fixed: true });
        links.push({ name: 'sessions', icon: 'shield-account' });
        links.push({ name: 'change_password', icon: 'lock' });
        links.push({ name: 'privacies', icon: 'shield-lock' });
      } else {
        links.push({ name: 'scoring', icon: 'counter', icon_fixed: true });
        links.push({ name: 'login', icon: 'book-arrow-left' });
        links.push({ name: 'register', icon: 'account-plus' });
        links.push({ name: 'contact_message', icon: 'email-edit' });
        links.push({ name: 'budget_sheet_plan', icon: 'piggy-bank', alt_names: ['budget_sheet_do','budget_sheet_check','budget_sheet_action']});
        links.push({ name: 'exchange_rate', icon: 'currency-jpy', icon_fixed: true });
        links.push({ name: 'posts', icon: 'post'});
        links.push({ name: 'privacies', icon: 'shield-lock' });
      }
      links.push({ name: 'terms', icon: 'shield-check' });
      if(this.$i18n.locale == 'ja'){
        links.push({ name: 'laws', icon: 'note' });
      }
      //links.push({ name: 'anti_social', icon: 'shield-sword' });
      return links;
    },
    footer_menus() {
      return [
        { name: 'home', icon: 'home' },
        //{ name: 'posts', icon: 'post' },
        { name: 'scoring', icon: 'counter', icon_fixed: true },
        { name: 'budget_sheet_plan', icon: 'piggy-bank', alt_names: ['budget_sheet_do','budget_sheet_check','budget_sheet_action']},
        this.isLoggedIn ? { name: 'contact_messages', icon: 'email-edit' } : { name: 'login', icon: 'book-arrow-left' },
        //{name: 'profile', icon: 'account'},
        //{name: 'order_form', icon: 'rocket-launch'},
        //{name: 'orders', icon: 'file-clock'},.push({ name: 'login', icon: 'book-arrow-left' }) : ,
        {name: 'menu', icon: 'view-grid'},
      ]
    },
  },
  watch: {
    $route (to, from){
      if(this.$route.name == 'contact_message_thread') {
          this.init();
          return;
      }
      if(!this.processing) {
        this.processing = true;
        setTimeout(() => {
          this.init();
          this.processing = false;
        }, 30000);
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    item_icon(item) {
      let icon = item.icon
      if(!this.is_active(item) && !item.icon_fixed) {
        icon = icon + '-outline'
      }
      return icon
    },
    item_active_class(item, prefix) {
      if(this.is_active(item)) {
        return prefix + '-active'
      }
      return prefix
    },
    is_active(item) {
      if(this.$route.name==item.name) {
        return true
      }
      if(item.alt_names && item.alt_names.includes(this.$route.name)) {
        return true
      }
      return false
    },
    init() {
      ApiRequestor.get('init').then(
        response => {
          this.contact_messages_unread_count = response.data.contact_messages_unread_count
        }
      );
    }
  },
}
</script>