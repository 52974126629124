<template>
  <div>
    <p><a class="link-a" href="https://www.moj.go.jp/isa/publications/materials/newimmiact_3_evaluate_index.html" target="blank">{{$t('views.scoring.ref_jp')}}</a></p>
    <p><a class="link-a" href="https://www.moj.go.jp/isa/content/930001667.pdf" target="blank">{{$t('views.scoring.ref_vn')}}</a></p>
  </div>
  <CMessage :messages="message.base" />
  <c-member-requestor />
  <CInputTag
    v-model="category"
    :options="categories"
  />
  <p>{{ $t('views.scoring.start') }}</p>
  <div v-for="(items, key) in topics[category]" class="mt-2">
    <h3 class="pl-2 border-l-4  border-primary">{{ $t('views.scoring.details.'+category+'.topic.'+key) }}</h3>
    <div
      v-for="(item, index) in items"
      class="flex items-center mx-1 my-1 border border-gray-200"
    >
      <input
        type="radio"
        v-model="answers[category][key]"
        :value="item"
        :id="key+index"
        :disabled="!isLoggedIn"
      />
      <label
        class="ml-1 mr-2 px-2 py-1 whitespace-pre-line block w-full"
        :for="key+index"
      >{{ $t('views.scoring.details.'+category+'.'+key+'.'+item)}}</label>
      <CLink
        v-if="answers[category][key]==item"
        icon="close-box-outline"
        class="text-danger"
        @click.prevent="answers[category][key]=null"
      />
    </div>
    <div class="whitespace-pre-line font-light text-sm ml-2 pl-2 border-l-4  border-warning" v-if="$te('views.scoring.details.'+category+'.topic.'+key+'_note')">{{ $t('views.scoring.details.'+category+'.topic.'+key+'_note') }}</div>
  </div>
  <CButton
    @click.prevent="create"
    label="score"
    :disabled="!isLoggedIn"
  />
  <div v-if="score[category]!=null" class="card-a bg-warning">
    {{ $t('views.scoring.total') }}: {{ score[category] }} {{ $t('views.scoring.point') }}
    <template v-if="score[category]>=70">
      <p v-if="score[category]>=80">{{ $t('views.scoring.p80_permanence') }}</p>
      <p v-if="score[category]>=70">{{ $t('views.scoring.p70_permanence') }}</p>
      <p><a class="link-a" href="https://www.moj.go.jp/isa/applications/procedures/nyuukokukanri07_00131.html" target="blank">{{$t('views.scoring.permanence_link')}}</a></p>
      <p>{{ $t('views.scoring.permanence_note') }}</p>
    </template>
    <p v-if="score[category]<70">{{ $t('views.scoring.under70_permanence') }}</p>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Scoring",
  data: function () {
    return {
      message: {},
      topics: {},
      categories: [
        {label: this.$t('views.scoring.category.i'), value: 'i'},
        {label: this.$t('views.scoring.category.ro'), value: 'ro'},
        {label: this.$t('views.scoring.category.ha'), value: 'ha'}
      ],
      category: 'ro',
      score: {
        i: null,
        ro: null,
        ha: null
      },
      answers: {
        i: {},
        ro: {},
        ha: {}
      },
      language: this.$i18n.locale
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      ApiRequestor.get('users/residence_scoring').then(
        response => {
          this.topics = response.data.meta
          if(response.data.user_scoring) {
            this.category = response.data.user_scoring.category
            this.score[this.category] = response.data.user_scoring.score
            this.answers[this.category] = response.data.user_scoring.answers
          }
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    create() {
      ApiRequestor.post('users/residence_scoring', {category: this.category, answers: this.answers[this.category]}).then(
        response => {
          this.score[this.category] = response.data
          this.result_showing = true
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>