<template>
  <multiselect
    v-model="$i18n.locale"
    :options="languages"
    :can-deselect="false"
    :can-clear="false"
    @select="changeLanguage()"
  >
    <template #singlelabel="{ value }">
      <div class="multiselect-single-label">
        <span :class="value.icon" /><span class="ml-2">{{ value.label }}</span>
      </div>
    </template>
    <template #option="{ option }">
      <span :class="option.icon" /><span class="ml-2">{{ option.label }}</span>
    </template>
  </multiselect>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "CLanguage",
  computed: {
    languages(){
      return [
        { value: 'vi', label: this.$t('views.language.vi'), icon: 'fi fi-vn' },
        { value: 'ja', label: this.$t('views.language.ja'), icon: 'fi fi-jp' }
      ]
    },
    availableLanguages() {
      return this.languages.map(obj => obj['value'])
    },
    queryLang() {
      return this.$route.query.lang
    }
  },
  watch: {
    queryLang(value, oldValue) {
      var currentQuery = { ...this.$route.query }
      delete currentQuery.lang
      this.$router.push({ query: currentQuery })
      if(this.$i18n.locale != value && this.availableLanguages.includes(value)) {
        this.$i18n.locale = value
        this.changeLanguage()
      }
    }
  },
  created() {
    this.$i18n.locale = ApiRequestor.getLanguage()
  },
  methods: {
    changeLanguage() {
      ApiRequestor.setLanguage(this.$i18n.locale);
      window.location.reload()
    }
  }
}
</script>