<template>
  <CLink
    :to="{name: 'contact_messages'}"
    translated
    :label="$t('views.action.back')"
    icon="arrow-left"
    class="btn-a-outline"
  />
  <CMessage :messages="message.base" />
  <div
    v-for="(item, index) in contact_messages"
    :key="index"
    :tabIndex="index"
    :autofocus="isFocusMessage(item.id)"
    class="mb-6"
  >
    <CContactMessage :contact-message="item" />
  </div>
  <CTextArea
    v-model="contact_message.body"
    :errors="message.body"
    rows="5"
  />
  <CButton
    label="send"
    @click.prevent="create"
  />
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: 'ContactMessageThread',
  data() {
    return {
      message: {},
      contact_messages: [],
      contact_message: {
        contact_message_id: this.$route.params.id,
        body: null
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    if(this.isLoggedIn) {
      this.load()
    } else {
        ApiRequestor.redirectMember()
    }
  },
  methods: {
    isFocusMessage(id) {
      return id == this.$route.query.m ? true : false
    },
    load() {
      ApiRequestor.get('current_user/contact_messages', {contact_message_id: this.$route.params.id} ).then(
        response => {
          this.contact_messages = response.data
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    },
    create(){
      ApiRequestor.post('current_user/contact_messages', {contact_message: this.contact_message} ).then(
        response => {
          this.contact_messages.push(response.data)
          this.contact_message.body = null
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    }
  },
}
</script>